import React from "react";
import { Switch as MSwitch, SwitchProps } from "@ds-proxy";

export function Switch(props: SwitchProps) {
  return (
    <div
      style={{
        width: 40,
        height: 25,
        marginLeft: 9,
        left: -9,
        top: -6.5,
        position: "relative",
      }}
    >
      <MSwitch {...props} />
    </div>
  );
}
