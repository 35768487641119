import React, { useState, useMemo, useEffect } from "react";
import { TableSettingsIcon, RevertIcon } from "assets/icons";
import { HighlightedTextComponent } from "shared/HighlightedTextComponent";
import { useUpdateEffect } from "react-use";
import deepEqual from "lodash/isEqual";
import { omit, upperFirst } from "lodash";
import {
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@ds-proxy";
import { ColSettings, ColumnsSettings, EntityData } from "@modules/table/tableSettingsTypes";
import { Box, Text, SearchInput, NoDataState, LinkButton } from "ui";
import { css } from "ui/css";
import { SortableList } from "ui/molecules/SortableList/SortableList";
import { RenderItemProps } from "ui/molecules/SortableList/SotableItem";
import { useModal } from "libs/hooks";

export type VisibleEntitiesSettingsProps = {
  className?: string;
  allEntities: EntityData[];
  columnsSettings?: ColumnsSettings;
  alwaysEnabledEntities?: string[];
  hiddenEntities?: string[];
  noDataFields?: string[];
  // order matters
  defaultColumnsSettings: ColumnsSettings;
  onChange: (selectedEntities: ColumnsSettings) => void;
  onSave: (selectedEntities: ColumnsSettings) => void;
  onCancel?: () => void;
  title?: string;
  minSelected?: number;
  selectedHasNotChanged?: boolean;
  showSelectAll?: boolean;
  TriggerComponent?: React.ElementType;
};

const visibleEntitiesSettingsStyles = css({
  display: "flex",
});

const topContainerStyles = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const resetButtonStyles = css({
  color: "var(--text-primary)",
  display: "flex",
  alignItems: "center",
  padding: "0",
});

const itemDraggableStyles = css({
  position: "absolute",
  zIndex: 1,
  top: 0,
  bottom: 0,
  width: "100%",
  right: 0,
  paddingRight: 5,
  cursor: "grab",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "center",
  span: {
    height: 2,
    width: 10,
    margin: 1.25,
    display: "block",
  },
});

const itemContainerStyles = css({
  "position": "relative",
  "border": "1px solid var(--outlines-1)",
  "borderRadius": "var(--border-radius-xs)",
  "padding": "var(--spacing-xs)  var(--spacing-m)",
  "margin": "var(--spacing-xs) 0",

  "&:hover": {
    background: "var(--background-active)",
    [`.${itemDraggableStyles().className}`]: {
      visibility: "visible",
    },
  },

  "variants": {
    active: {
      true: {
        background: "var(--background-active)",
        [`.${itemDraggableStyles().className}`]: {
          visibility: "visible",
        },
      },
      false: {
        background: "",
      },
    },
  },
  [`.${itemDraggableStyles().className}`]: {
    visibility: "hidden",
  },
});

const itemStyles = css({
  padding: 0,
  zIndex: 2,
  position: "relative",
  gap: "var(--spacing-s)",
  b: {
    backgroundColor: "var(--semantic-warning-background)",
  },
});

const itemCheckboxStyles = css({
  padding: "2px !important",
});

const searchStyles = css({
  marginBottom: 12,
});

const triggerButtonStyles = css({
  "padding": "6px !important",
  "margin": "-4px 0",
  "maxHeight": 30,
  "&:hover": {
    background: "var(--background-active)",
    borderRadius: 3,
  },
});

export const VisibleEntitiesSettings = ({
  className,
  defaultColumnsSettings,
  columnsSettings,
  allEntities,
  onChange,
  title = "Select visible columns",
  onSave,
  onCancel,
  minSelected = 1,
  selectedHasNotChanged,
  alwaysEnabledEntities,
  hiddenEntities,
  showSelectAll,
  noDataFields,
  TriggerComponent = IconButton,
}: VisibleEntitiesSettingsProps) => {
  const [emptyFieldsAreHidden, setEmptyFieldsAreHidden] = useState(true);
  const settings = columnsSettings ?? defaultColumnsSettings;

  const isInDefaultState = useMemo(() => {
    return deepEqual(
      defaultColumnsSettings.map((x) => omit(x, "name")),
      columnsSettings?.map((x) => omit(x, "name"))
    );
  }, [defaultColumnsSettings, columnsSettings]);

  const [search, setSearch] = useState("");

  const alwaysEnabledEntitiesSet = useMemo(() => {
    return new Set(alwaysEnabledEntities || []);
  }, [alwaysEnabledEntities]);

  const hiddenEntitiesSet = useMemo(() => {
    if (!emptyFieldsAreHidden) {
      return new Set(hiddenEntities || []);
    }
    return new Set((hiddenEntities || []).concat(noDataFields || []));
  }, [hiddenEntities, noDataFields, emptyFieldsAreHidden]);

  const [settingsState, setSettingsState] = useState(settings);
  useUpdateEffect(() => {
    setSettingsState(settings);
  }, [settings]);

  const selectedSet = useMemo(() => {
    return new Set(settings.filter((col) => col.visible).map(({ id }) => id));
  }, [settings]);

  const disableUnselect = settings.filter((col) => col.visible).length <= minSelected;

  useEffect(() => {
    const set = new Set();

    allEntities.forEach((c) => {
      if (set.has(c.id)) {
        throw new Error("duplicated column definition id: " + c.id);
      }
      set.add(c.id);
    });
  }, []);

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const toggleID = e.currentTarget.name;
    onChange(
      settingsState.map((col) => {
        if (col.id === toggleID) {
          return {
            ...col,
            visible: !col.visible,
          };
        }
        return col;
      })
    );
  };

  const handleSelectAll = () => {
    if (!isAllSelected) {
      return onChange(
        settingsState.map((col) => ({
          ...col,
          visible: true,
        }))
      );
    }

    const isAllSelectedByDefault = defaultColumnsSettings.every((col) => col.visible);
    const selectOnlyLocked = settingsState.map((col) => ({
      ...col,
      visible: alwaysEnabledEntitiesSet.has(col.id),
    }));
    const defaultSelectionWithoutReordering = settingsState.map((col) => ({
      ...col,
      visible: defaultColumnsSettings.find(({ id }) => id === col.id)?.visible ?? false,
    }));

    return isAllSelectedByDefault
      ? onChange(selectOnlyLocked)
      : onChange(defaultSelectionWithoutReordering);
  };

  const entitiesToShow = useMemo(() => {
    const searchTrimmed = search.trim().toLowerCase();

    const mapper = ({ id, name, visible }: ColSettings) => {
      const formattedName = name?.replace(/(-|_)/g, " ").toLowerCase().split(" ").join(" ");
      return { id, name: upperFirst(formattedName), visible };
    };

    const filterOutHiddenEntities = (item: EntityData) => !hiddenEntitiesSet.has(item.id);

    if (!searchTrimmed) {
      return settings
        .filter((v) => Boolean(v))
        .map(mapper)
        .filter(filterOutHiddenEntities);
    }

    return settingsState
      .filter(
        (entity) =>
          // MUI-provided columns can have undefined name
          entity.name?.toLocaleLowerCase().includes(searchTrimmed) &&
          filterOutHiddenEntities(entity)
      )
      .map(mapper);
  }, [settingsState, search, hiddenEntitiesSet]);

  const isAllSelected = useMemo(
    () => settingsState.every((v) => hiddenEntitiesSet.has(v.id) || v.visible),
    [settingsState]
  );

  const handleResetDefault = () => {
    onChange(defaultColumnsSettings);
    setSettingsState(defaultColumnsSettings);
  };

  const handleReorder = (newSettings: ColumnsSettings) => {
    onChange(newSettings);
    setSettingsState(newSettings);
  };

  const handleSave = () => {
    onSave(settingsState);
  };

  return (
    <SelectListModal
      title={title}
      className={className}
      onCancel={onCancel}
      TriggerComponent={TriggerComponent}
      onSave={handleSave}
      search={search}
      setSearch={setSearch}
      onSelectAll={handleSelectAll}
      showSelectAll={showSelectAll}
      isAllSelected={isAllSelected}
      disabledSave={selectedHasNotChanged}
      selectedSet={selectedSet}
      alwaysEnabledEntitiesSet={alwaysEnabledEntitiesSet}
      footerExtraContent={
        noDataFields?.length ? (
          <LinkButton
            style={{ margin: "6px 8px", display: "inline-block" }}
            onClick={() => setEmptyFieldsAreHidden(!emptyFieldsAreHidden)}
            color="primary"
          >
            {emptyFieldsAreHidden ? "Show more" : "Show less"}
          </LinkButton>
        ) : null
      }
      onOrderChange={handleReorder}
      entitiesToShow={entitiesToShow}
      onSelectChange={handleSelectChange}
      disableUnselect={disableUnselect}
      topExtraRenderer={
        <IconButton
          classes={{
            root: resetButtonStyles().className,
          }}
          onClick={handleResetDefault}
          disabled={isInDefaultState}
        >
          <RevertIcon style={{ marginRight: 2 }} />
          <Text>Reset to default</Text>
        </IconButton>
      }
    />
  );
};

export type SelectListModalProps = {
  className?: string;
  TriggerComponent: React.ElementType;
  // onOpenClose: (open: boolean) => void;
  onSave: () => void;
  onSelectAll: () => void;
  onCancel?: () => void;
  showSelectAll?: boolean;
  disabledSave?: boolean;
  disableUnselect?: boolean;
  isAllSelected?: boolean;
  title: string;
  saveBtnLabel?: string;
  search: string;
  open?: boolean;
  onOpenCloseChange?: (open: boolean) => void;
  setSearch: (search: string) => void;
  topExtraRenderer?: React.ReactNode;
  itemsExtraContent?: React.ReactNode;
  footerExtraContent?: React.ReactNode;
  entitiesToShow: any[];
  onOrderChange?: (newSettings: ColumnsSettings) => void;
  selectedSet: Set<string>;
  alwaysEnabledEntitiesSet?: Set<string>;
  onSelectChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  renderItem?: (props: RenderItemProps) => React.ReactChild;
};

export const SelectListModal = ({
  className,
  TriggerComponent,
  onSave,
  onCancel,
  title,
  topExtraRenderer,
  search,
  setSearch,
  showSelectAll,
  isAllSelected,
  onSelectAll,
  disabledSave,
  footerExtraContent,
  entitiesToShow,
  onOrderChange,
  selectedSet,
  onSelectChange,
  alwaysEnabledEntitiesSet = new Set(),
  disableUnselect,
  saveBtnLabel = "Save",
  itemsExtraContent,
  renderItem,
  open: openProps,
  onOpenCloseChange = () => {},
  ...rest
}: SelectListModalProps) => {
  const handleSave = () => {
    onSave();
    onOpenCloseChange(false);
    modalApi.handleClose();
  };

  const handleOpenClose = (visible: boolean) => {
    onOpenCloseChange(visible);
    if (!visible) {
      onCancel?.();
      modalApi.handleClose();
    } else {
      modalApi.handleOpen();
    }
  };

  const renderItemDefault = ({ item, props, active, index }: RenderItemProps) => {
    const isAlwaysEnabled = alwaysEnabledEntitiesSet.has(item.id);
    const canDnD = Boolean(onOrderChange);
    return (
      <div className={itemContainerStyles({ active }).className}>
        {canDnD && (
          <div className={itemDraggableStyles().className} {...props}>
            <span />
            <span />
            <span />
          </div>
        )}
        <FormControlLabel
          className={itemStyles().className}
          label={<HighlightedTextComponent highlightSubstring={search} title={item.name} />}
          data-testid={"visible-entity-label-" + index}
          control={
            <Checkbox
              disabled={(disableUnselect && selectedSet.has(item.id)) || isAlwaysEnabled}
              onChange={onSelectChange}
              className={itemCheckboxStyles().className}
              checked={item.visible}
              name={item.id}
            />
          }
        />
      </div>
    );
  };

  const [modal, modalApi] = useModal(({ open, onClose }) => {
    const isOpen = openProps ?? open;
    return (
      <Dialog
        open={isOpen}
        onClose={(e: any) => {
          onClose(e);
          onOpenCloseChange(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className={topContainerStyles().className}>
          {title}
          {topExtraRenderer}
        </DialogTitle>
        <DialogContent>
          <SearchInput
            initialValue={search}
            placeholder="Search"
            onCancel={() => setSearch("")}
            onRequestSearch={setSearch}
            variant="outlined"
            className={searchStyles().className}
            inputProps={{
              "role": "search",
              "data-testid": "visible-entities-search",
            }}
          />
          {itemsExtraContent}
          {showSelectAll && (
            <div className={itemContainerStyles({ active: false }).className}>
              <FormControlLabel
                className={itemStyles().className}
                label="Select All"
                control={
                  <Checkbox
                    onChange={onSelectAll}
                    className={itemCheckboxStyles().className}
                    checked={isAllSelected}
                    name="select-all"
                  />
                }
              />
            </div>
          )}
          <Box sx={{ height: "50vh", overflow: "hidden" }}>
            {search && entitiesToShow.length === 0 && <NoDataState title="Nothing found" />}
            <SortableList<ColSettings>
              onReorder={onOrderChange}
              items={entitiesToShow}
              dndEnabled={search.length === 0}
              height="100%"
              footerExtraContent={footerExtraContent}
              renderItem={renderItem || renderItemDefault}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenClose(false)} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            data-testid="save-table-settings"
            variant="contained"
            color="primary"
            style={{ marginLeft: 4 }}
            onClick={handleSave}
            disabled={disabledSave}
          >
            {saveBtnLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  });

  return (
    <>
      <Box className={visibleEntitiesSettingsStyles({ className }).className} {...rest}>
        <TriggerComponent
          data-testid="visible-settings-btn"
          className={triggerButtonStyles().className}
          onClick={() => {
            handleOpenClose(true);
          }}
        >
          <TableSettingsIcon />
        </TriggerComponent>
      </Box>

      {modal}
    </>
  );
};
