import { useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "react-use";
import {
  ThemeOption,
  useUpdateUserPreferences,
  useUserPreferences,
} from "shared/user-preferences/userPreferences";
import constate from "constate";
import { applyTheme } from "@ds-ui/theme";
// eslint-disable-next-line no-restricted-imports
import { AvailableTheme } from "@ds-proxy/theme/types";

export const SUPPORT_DARK_THEME_ATTR = "data-support-dark-theme";
const MATCH_MEDIA_DARK = "(prefers-color-scheme: dark)";
export const LS_COLOR_THEME = "ColorTheme";

// TODO: Simplify this logic and move it to the ds package, extract the server logic
function useThemeHook() {
  const [activeTheme, setActiveTheme] = useState<AvailableTheme>("light");
  const [hasDarkThemeSupport, setHasDarkThemeSupport] = useState(true);
  const [userSelectedColorTheme, setUserSelectedColorTheme] = useLocalStorage<ThemeOption>(
    LS_COLOR_THEME,
    ThemeOption.System
  );

  const { data, isSuccess } = useUserPreferences();
  const serverSavedTheme = data?.settings?.theme;
  const hasThemeFromServerUpdatedRef = useRef(false);

  const setDarkThemeSupport = useCallback((supportsDarkTheme: boolean) => {
    document.body.setAttribute(SUPPORT_DARK_THEME_ATTR, supportsDarkTheme ? "true" : "false");
    setHasDarkThemeSupport(supportsDarkTheme);
  }, []);

  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences();

  const updateTheme = async (theme: ThemeOption) => {
    if (theme === userSelectedColorTheme) {
      return;
    } // Avoid redundant updates

    setUserSelectedColorTheme(theme);
    await updateUserPreferences({ settings: { ...data?.settings, theme } });
  };

  const setActiveThemeAndApply = (theme: AvailableTheme) => {
    setActiveTheme(theme);
    applyTheme(theme);
  };

  // Update the theme based on user preferences
  // The user might have changed this on another machine
  useEffect(() => {
    if (isSuccess && !hasThemeFromServerUpdatedRef.current && serverSavedTheme) {
      setUserSelectedColorTheme(serverSavedTheme);
      hasThemeFromServerUpdatedRef.current = true;
    }
  }, [serverSavedTheme, isSuccess, setUserSelectedColorTheme]);

  useEffect(() => {
    if (!hasDarkThemeSupport) {
      setActiveThemeAndApply(ThemeOption.Light);
      return;
    }

    if (userSelectedColorTheme === ThemeOption.System) {
      const mediaQuery = window.matchMedia(MATCH_MEDIA_DARK);

      const handleThemeChange = () => {
        setActiveThemeAndApply(mediaQuery.matches ? ThemeOption.Dark : ThemeOption.Light);
      };

      // Set initial theme
      handleThemeChange();

      // Listen for changes
      mediaQuery.addEventListener("change", handleThemeChange);

      return () => {
        mediaQuery.removeEventListener("change", handleThemeChange);
      };
    }

    setActiveThemeAndApply(userSelectedColorTheme || "light");
    return;
  }, [userSelectedColorTheme, hasDarkThemeSupport]);

  return {
    updateTheme,
    setDarkThemeSupport,
    colorTheme: userSelectedColorTheme,
    isSuccess,
    activeTheme,
  };
}

export const [ThemeStateProvider, useTheme] = constate(useThemeHook);
