import React from "react";
import { Chip as MChip, ChipProps } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { css } from "ui/css";

const filterChipStyles = css({
  "height": 28,
  "background": "var(--background-base0)",
  "borderColor": "var(--palette-divider)",
  "color": "var(--text-secondary)",
  "borderRadius": "4px",
  "fontWeight": 500,
  "fontSize": 13,
  "textTransform": "none",
  "justifyContent": "flex-start",
  "minWidth": 0,
  "&:active": {
    boxShadow: "none",
  },
  "&.MuiChip-clickable:hover": {
    background: "var(--background-base1)",
    color: "var(--text-primary)",
  },
  ".MuiChip-label": {
    color: "inherit",
    paddingRight: "0",
    paddingLeft: "8px",
    fontSize: 13,
    lineHeight: 1.2,
  },
  ".MuiChip-icon": {
    marginLeft: "6px",
    marginRight: "-2px",
    color: "var(--text-primary)",
  },
  ".MuiChip-deleteIcon": {
    "display": "flex",
    "alignItems": "center",
    "padding": "0 4px 0 8px",
    "marginRight": 0,
    "marginLeft": "auto",
    "height": "100%",
    "color": "var(--text-primary)",
    "&:hover": {
      color: "var(--text-primary)",
    },
  },
});

export const FilterChip = React.forwardRef(function FilterChip(props: ChipProps, ref: any) {
  return (
    <MChip
      ref={ref}
      variant="outlined"
      deleteIcon={
        props.onDelete ? (
          <div>
            <Icon name={"CloseOutlined"} size={"s"} />
          </div>
        ) : undefined
      }
      {...props}
      className={`${filterChipStyles().className} ${props.className}`}
    />
  );
});
