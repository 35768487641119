/* eslint-disable */
import React, { FunctionComponent } from "react";
import { FieldRenderProps } from "react-final-form";
import { css, styled } from "ui/css";
import { Menu, MenuItem, InputAdornment } from "@ds-proxy";
import { MoreVert } from "@mui/icons-material";
import { TextFieldComponent } from "../Fields";
import { SuggestCodeEditor, CodeEditor } from "libs/code-editor";
import { theme } from "ui/theme";

export interface ILuceneFieldState {
  anchorEl: null | HTMLElement;
  template: null | ILuceneTemplate; // used temlate, will be processed on menu exit
}

export interface ILuceneTemplate {
  Label: FunctionComponent<React.PropsWithChildren<any>>; // MenuItem label
  getValue(value: string): string; // Change Input Value
  restoreSelection?: number[]; // Selection range
}

export interface ILuceneFieldProps extends FieldRenderProps {
  templates: ILuceneTemplate[];
  suggest?: boolean;
  className?: string;
  suggestConfig?: any;
  label?: string;
}

const inputClassName = css({
  "fontSize": "15px",
  "textarea,  pre": { border: "none", padding: "10px 10px 10px 0 !important" },
});

const HoverableMoreVert = styled(MoreVert, {
  "cursor": "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
  },
});

/**
 * LuceneField
 *
 * @description
 * Component for react-final-form/Field
 * Renders input with helper menu (if value is not empty) for value formatting via templates
 *
 * @param templates: Array<Template>
 * @see src/modules/graph/organisms/SidePanel/Search/queryTemplates
 *
 * @example
 * ```
 * <Field
 *  name='lorem ipsum'
 *  component={LuceneField}
 *  label="Lorem ipsum"
 *  templates: Template[]
 * />
 * ```
 */
export class LuceneField extends React.Component<ILuceneFieldProps, ILuceneFieldState> {
  $input = React.createRef<HTMLInputElement>();

  state: ILuceneFieldState = {
    anchorEl: null,
    template: null,
  };

  handleMoreClick = (event: any) => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  handleTemplateClick = (template: ILuceneTemplate) => () => {
    this.setState({ template });
    this.handleClose();
  };

  /**
   * If template was selected
   * - change input value
   * - setup selection range
   * - focus input
   */
  handleExit = () => {
    if (this.state.template) {
      const { restoreSelection: [start = 0, end = 0] = [], getValue } = this.state.template;

      const value = getValue(this.props.input.value);

      this.props.input.onChange(value);
      setTimeout(() => {
        this.$input.current!.setSelectionRange(start, end);
      });
    }
    this.$input.current!.focus();
    this.setState({ template: null });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      templates,
      input: { value },
      suggest,
      suggestConfig,
      ...rest
    } = this.props;
    let C = suggest ? SuggestCodeEditor : CodeEditor;

    return (
      <TextFieldComponent
        {...rest}
        input={this.props.input}
        inputProps={{
          className: inputClassName().className,
          suggestConfig: suggestConfig,
        }}
        InputProps={{
          inputComponent: C,
          inputRef: this.$input,
          endAdornment:
            (!!value && (
              <InputAdornment position="end">
                <HoverableMoreVert onClick={this.handleMoreClick} />
                <Menu
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={this.handleClose}
                  TransitionProps={{
                    onExited: this.handleExit,
                  }}
                >
                  {templates.map((template, i) => (
                    <MenuItem key={i} onClick={this.handleTemplateClick(template)}>
                      <template.Label value={this.props.input.value} />
                    </MenuItem>
                  ))}
                </Menu>
              </InputAdornment>
            )) ||
            undefined,
        }}
      />
    );
  }
}
