import React from "react";
import { Box, HStack, VStack } from "ui";

export interface GridPageTemplateProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  isSidePanelOpen?: boolean;
  contentWrapperStyle?: React.CSSProperties;
  sidepanelWrapperStyle?: React.CSSProperties;
  sidePanel?: React.ReactNode;
  filters?: React.ReactNode;
  counters?: React.ReactNode;
  className?: string;
}

/**
 * @deprecated All new pages should adhere to the new design system
 */
export function GridPageTemplate({
  children,
  header,
  filters,
  counters,
  sidePanel,
  contentWrapperStyle,
  sidepanelWrapperStyle,
  isSidePanelOpen,
  className,
}: GridPageTemplateProps) {
  return (
    <VStack sx={{ height: "100%", width: "100%", overflow: "hidden" }} className={className}>
      <HStack
        sx={{
          height: "100%",
        }}
      >
        <VStack
          sx={{
            height: "100%",
            padding: `0 var(--spacing-xl)`,
            overflow: "hidden",
            transition: "flex-shrink .3s ease-in",
            width: "100%",
            flexShrink: isSidePanelOpen ? 1 : 0,
            ...contentWrapperStyle,
          }}
        >
          {header && (
            <Box sx={{ marginTop: "var(--spacing-l)", marginBottom: "var(--spacing-s)" }}>
              {header}
            </Box>
          )}
          {filters && <Box sx={{ marginBottom: "var(--spacing-xl)" }}>{filters}</Box>}
          {counters && <Box sx={{ marginBottom: "var(--spacing-2xl)" }}>{counters}</Box>}
          {children}
        </VStack>
        {sidePanel && (
          <Box
            sx={{
              height: "100%",
              minWidth: "450px",
              maxWidth: "600px",
              width: "40%",
              flexShrink: 0,
              ...sidepanelWrapperStyle,
            }}
          >
            {sidePanel}
          </Box>
        )}
      </HStack>
    </VStack>
  );
}
