import React, { forwardRef, useState } from "react";
import { Button, Typography } from "@ds-proxy";
import { css } from "ui/css";
import { Box, BoxComponent, CssPropType } from "ui/atoms/Box";
import { HStack, Overlay } from "libs/layouts";

export type ConfirmDeleteProps = {
  onClick: () => any;
  children: (setOpen: any) => any;
  confirm: string;
  cancel: string;
  message: string;
  className?: string;
};

export const ConfirmDelete: BoxComponent<ConfirmDeleteProps> = forwardRef(function ConfirmDelete(
  { onClick, children, confirm, cancel, message, className, ...rest },
  ref
) {
  return (
    <ConfirmDeletePure
      {...rest}
      as={Overlay}
      className={className}
      renderConfirm={(onClose) => (
        <HStack space={3} align="center" ref={ref}>
          <Typography>{message}</Typography>
          <Button onClick={onClose}>{cancel}</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();
              onClick();
            }}
          >
            {confirm}
          </Button>
        </HStack>
      )}
    >
      {children}
    </ConfirmDeletePure>
  );
});

const confirmDeleteStyles = css({
  background: "var(--background-base0)",
  display: "flex",
  alignItems: "center",
});

export function ConfirmDeletePure({
  children,
  className,
  renderConfirm,
  ...rest
}: {
  children: (setOpen: any) => JSX.Element;
  className?: string;
  renderConfirm: (onClose: any) => JSX.Element;
  as?: any;
  sx?: CssPropType;
}) {
  const [open, setOpen] = useState(false);
  if (!open) {
    return children(setOpen.bind(null, true));
  }

  return (
    <Box
      className={confirmDeleteStyles({ className }).className}
      sx={{ paddingLeft: "var(--spacing-s)", paddingRight: "var(--spacing-s)" }}
      {...rest}
    >
      {renderConfirm(setOpen.bind(null, false))}
    </Box>
  );
}
