import React, { HTMLAttributes } from "react";
import classNames from "classnames";
// eslint-disable-next-line no-restricted-imports
import { theme } from "@ds-proxy/theme";
import styles from "./Chip.module.css";

/*
 * TODO
 * - Move to ds proxy
 * */

export type ChipProps = HTMLAttributes<HTMLDivElement> &
  React.PropsWithChildren & {
    color?: string;
    background?: string;
    disabled?: boolean;
  };

export function Chip({
  children,
  color,
  background = theme.customColors.random.grey2,
  className,
  disabled,
  ...rest
}: ChipProps) {
  const bgAndColor = background
    ? {
        background: background,
        color: color ?? theme.palette.getContrastText(background),
      }
    : {
        background: "var(--background-base1)",
        color: "var(--palette-text-primary)",
      };

  return (
    <div
      {...rest}
      className={classNames(styles.chip, {
        [styles.disabled]: disabled,
      })}
      style={{
        ...bgAndColor,
      }}
      aria-disabled={disabled}
    >
      {children}
    </div>
  );
}
