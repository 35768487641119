import React from "react";
import { Button, ButtonProps } from "@ds-proxy";
import { Box } from "ui/atoms/Box";
import { Text } from "ui/atoms/Text";
import { css } from "ui/css";

export type DashboardSidepanelProps = {
  children?: React.ReactNode;
  className?: string;
  width?: number | string;
};

const dashboardSidepanelStyles = css({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  overflow: "hidden",
  background: "var(--background-base0)",
  padding: "20px 24px",
});

const topContent = css({
  flexDirection: "flex-start",
  paddingBottom: 12,
});

const bottomContent = css({
  marginTop: "auto",
  paddingTop: 12,
});

const buttonStyles = css({
  width: "100%",
  textTransform: "none",
  marginTop: 12,
});

export type ChildProps = {
  children: React.ReactNode;
};

const ActionButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      className={buttonStyles({ className }).className}
      {...props}
    />
  );
};

const TopContent = ({ children }: ChildProps) => {
  return <Box className={topContent().className}>{children}</Box>;
};

const BottomContent = ({ children }: ChildProps) => {
  return <Box className={bottomContent().className}>{children}</Box>;
};

const mainContentStyles = css({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflow: "hidden",
});

// scrollable
const MainContent = ({ children }: ChildProps) => {
  return <Box className={mainContentStyles().className}>{children}</Box>;
};

const titleStyles = css({
  fontWeight: "400 !important",
  paddingBottom: 6,
});

const Title = ({ children }: ChildProps) => {
  return (
    <Text className={titleStyles().className} variant="header5M">
      {children}
    </Text>
  );
};

interface DashboardSidepanelComponent
  extends React.ForwardRefExoticComponent<
    DashboardSidepanelProps & React.RefAttributes<HTMLDivElement>
  > {
  BottomContent: typeof BottomContent;
  TopContent: typeof TopContent;
  ActionButton: typeof ActionButton;
  Title: typeof Title;
  MainContent: typeof MainContent;
}

export const DashboardSidepanel = React.forwardRef<HTMLDivElement, DashboardSidepanelProps>(
  ({ children, width = 400, className, ...rest }, ref) => {
    return (
      <Box
        style={{ width }}
        data-testid="dashbord-sidepanel"
        className={dashboardSidepanelStyles({ className }).className}
        ref={ref}
        {...rest}
      >
        {children}
      </Box>
    );
  }
) as DashboardSidepanelComponent;

DashboardSidepanel.TopContent = TopContent;
DashboardSidepanel.BottomContent = BottomContent;
DashboardSidepanel.ActionButton = ActionButton;
DashboardSidepanel.Title = Title;
DashboardSidepanel.MainContent = MainContent;

DashboardSidepanel.displayName = "DashboardSidepanel";
