import React from "react";
import { ENV } from "runenv";
import type { ForwardRefComponent } from "@radix-ui/react-polymorphic";
import { ZStack } from "libs/layouts";

type ProgressProps = {
  /**
   * list of options to display
   */
  options: Array<{
    value: number;
    color?: string;
    className?: string;
    label?: string;
    isActive?: boolean;
  }>;
  /**
   * either options value are overlapping or not
   * for values 20 and 30 if they are overlapping they will be shown as
   * | 20% | 10% |
   */
  overlap?: boolean;
  /**
   * with max value 1000, 100 will be shown as 10%
   */
  maxValue?: number;
  /**
   * min value for sector if value is 0
   * from 0 to 100, is not effected by maxValue
   */
  // TODO implement
  minValue?: number;
  /**
   * when calculate percentage using Math.floor, the remainders are thrown away
   * It can cause the resulting width to be slightly less than 100
   * Use this parameter to add remainders to last item to always get 100 width
   */
  shouldRoundToFullWidth?: boolean;
  /**
   * min width in case value is very small but still > 0
   * percent calculation via Math.floor can result to 0 width
   */
  minWidth?: string | number;
};

type PolymorphicBox = ForwardRefComponent<"div", ProgressProps & { divider?: boolean }>;

export const LineProgress: PolymorphicBox = React.forwardRef(function LineProgress(
  {
    as: Comp = "div",
    options,
    overlap,
    maxValue = 100,
    style,
    shouldRoundToFullWidth,
    minWidth,
    divider = true,
    minValue: _,
    ...props
  },
  forwardedRef
) {
  let percent = 0;
  if (overlap) {
    options.sort((a, b) => a.value - b.value);
  }
  const hasActive = options.find((el) => el.isActive);
  const items = options
    .filter((el) => el.value > 0)
    .reduce((acc: JSX.Element[], el, i, arr) => {
      const value =
        shouldRoundToFullWidth && !overlap && i === arr.length - 1
          ? 100 - percent
          : Math.floor((el.value / maxValue) * 100);
      const last = i === arr.length - 1;
      const width = overlap ? value + "%" : percent + value + "%";
      acc.unshift(
        <div
          title={el.label}
          key={i}
          className={el.className}
          style={{
            width: divider ? `calc(${width} + ${i}px)` : width,
            minWidth: minWidth && el.value > 0 ? minWidth : "auto",
            height: "100%",
            borderRadius: divider
              ? last
                ? "var(--border-radius-sm)"
                : 0
              : "var(--border-radius-sm)",
            zIndex: el.isActive || !hasActive ? 1 : 0,
            opacity: el.isActive || !hasActive ? 1 : 0.8,
            ...(el.color ? { background: el.color } : {}),
          }}
        />
      );
      if (divider && !last && width !== "0%") {
        acc.unshift(
          <div
            className={el.className}
            key={i + 100}
            style={{
              width: `calc(${width} + ${i + 1}px)`,
              height: "100%",
              background: "var(--background-base1)",
              zIndex: el.isActive || !hasActive ? 1 : 0,
            }}
          />
        );
      }

      percent += value;
      return acc;
    }, []);
  if ((Math.floor(percent) > 100 && !overlap) || isNaN(percent)) {
    if (ENV.IS_BETA_ENV) {
      throw new Error(
        `Options have more than 100% total\n${JSON.stringify(
          options
        )}, maxValue: ${maxValue}, value: ${percent}`
      );
    }
  }
  return (
    <ZStack
      as={Comp}
      ref={forwardedRef}
      fullWidth
      style={{
        height: 5,
        background: "var(--background-base3)",
        borderRadius: "var(--border-radius-sm)",
        overflow: "hidden",
        ...style,
      }}
      alignX="flex-start"
      {...props}
    >
      {items}
    </ZStack>
  );
});
