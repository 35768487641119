import React, { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { VStack, HStack, Text, space, CssPropType, Loader } from "ui";
import { css } from "ui/css";

type TabsType = {
  sx?: CssPropType;
  isLoading?: boolean;
  children: React.ReactNode;
};

type TabGroupType = {
  label: string;
  items: TabType[];
  defaultExpanded?: boolean;
};

type TabType = {
  value: string;
  label: string;
  isSelected: boolean;
  onChange: (value: string) => void;
  sx?: CssPropType;
};

const tabsClass = css({
  "backgroundColor": "var(--background-idle)",
  "padding": space["3"],
  "paddingLeft": space["2"],
  "marginBottom": 1,
  "cursor": "pointer",
  "&:hover": {
    backgroundColor: "var(--background-active)",
  },
  "variants": {
    isSelected: {
      true: {
        backgroundColor: "var(--background-active)",
        borderLeftWidth: 2,
        borderLeftColor: "var(--palette-primary-main)",
        borderLeftStyle: "solid",
      },
    },
  },
});

export const Tab = ({ value, label, isSelected, onChange, sx }: TabType) => {
  return (
    <HStack
      key={value}
      className={tabsClass({ isSelected }).className}
      onClick={() => onChange(value)}
      sx={sx}
    >
      <Text bold={isSelected}>{label}</Text>
    </HStack>
  );
};

export const TabGroup = ({ label, items, defaultExpanded = false }: TabGroupType) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <VStack>
      <div
        className="flex flex-row justify-between pt-4 pr-4 pb-2 mb-[1px] cursor-pointer"
        style={{
          paddingLeft: "10px",
          backgroundColor: "rgb(241, 243, 244)",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Text>{label}</Text>
        <ExpandMore
          className={`self-center transition ease-in-out`}
          style={{ transform: expanded ? "rotate(-180deg)" : "rotate(0deg)" }}
        />
      </div>
      {expanded && (
        <>
          {items.map((item) => (
            <Tab {...item} key={item.value} sx={{ paddingLeft: "26px" }} />
          ))}
        </>
      )}
    </VStack>
  );
};

export const Tabs = ({ children, sx, isLoading = false }: TabsType) => {
  return (
    <VStack sx={{ maxWidth: 270, minWidth: 270, ...sx }}>
      {isLoading ? <Loader loading size={30} /> : children}
    </VStack>
  );
};
