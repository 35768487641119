import { GRID_CHECKBOX_SELECTION_COL_DEF, GridAlignment } from "@mui/x-data-grid-pro";
import { CHGridColDef } from "../types";
import { gridSpacing } from "../theme";

const CHECKBOX_WIDTH = 24;
const CHECKBOX_COLUMN_WIDTH = CHECKBOX_WIDTH + 2 * gridSpacing;

export const CHECKBOX_COLUMN_CONFIG: CHGridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  width: CHECKBOX_COLUMN_WIDTH,
  maxWidth: CHECKBOX_COLUMN_WIDTH,
  minWidth: CHECKBOX_COLUMN_WIDTH,
  flex: 0,
  align: "left" as GridAlignment,
  headerAlign: "left" as GridAlignment,
  hideable: false,
};

export const CHECKBOX_COLUMN_CONFIG_NEW_STYLE: CHGridColDef = {
  ...CHECKBOX_COLUMN_CONFIG,
  width: 60,
  maxWidth: 60,
  minWidth: 60,
};
