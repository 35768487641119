import React from "react";
import { Typography } from "@ds-proxy";
import { theme, Box, space } from "ui";
import { Stack, HStack } from "libs/layouts";
import { LineProgress } from "ui/atoms/Progress";

const getPercentChartBackground = (isSelected: boolean) =>
  isSelected ? "white" : theme.panelBackground.selectedColor;

export type FilterCardProps = {
  label: string;
  counter: number;
  subLabel: string;
  actions?: JSX.Element | null;
  progressColors: string[];
  progressValues: number[];
  selected: boolean;
  onClick?: (e: any) => any;
  overlappingProgress?: boolean;
  progressActive?: boolean[];
  countTestId?: string;
};
export function FilterCard({
  label,
  counter,
  subLabel,
  actions = null,
  selected,
  onClick = undefined,
  progressValues,
  progressColors,
  overlappingProgress,
  progressActive,
  countTestId,
}: FilterCardProps) {
  const isDisabled = counter === 0;
  return (
    <Box
      sx={{
        paddingX: space[2],
        paddingY: space[3],
        background: selected ? theme.panelBackground.selectedColor : "#FFFFFF",
        width: "320px",
      }}
      as={Stack}
      space={5}
      style={{
        boxShadow: selected ? "unset" : "0px 5px 12px rgba(0, 0, 0, 0.1)",
        cursor: !isDisabled ? "pointer" : "default",
      }}
      onClick={!isDisabled ? onClick : undefined}
    >
      <HStack>
        <Box disabled={isDisabled}>
          <Typography variant="subtitle2">{label}</Typography>
        </Box>
        <HStack.Spacer />
        {actions}
      </HStack>
      <Box as={Stack} space={2} disabled={isDisabled}>
        <HStack align="baseline">
          <Typography variant="h4" data-testid={countTestId}>
            {counter}
          </Typography>{" "}
          <Typography variant="body2">{subLabel}</Typography>
        </HStack>
        <LineProgress
          overlap={overlappingProgress}
          minValue={5}
          options={progressValues.map((el, i) => ({
            value: el ?? 0,
            color: progressColors[i],
            isActive: progressActive?.[i],
          }))}
          style={{
            background: getPercentChartBackground(selected),
          }}
        />
      </Box>
    </Box>
  );
}
