import React from "react";
import { Typography } from "@ds-proxy";

interface CustomNoRowsProps {
  hasActiveFilters: boolean;
  title?: string;
  description?: string;
}

export function GridNoRowsOverlay({
  hasActiveFilters,
  title: titleProps,
  description: descriptionProps,
}: CustomNoRowsProps) {
  const title = hasActiveFilters ? "No one found" : "There is no data yet";
  const description = hasActiveFilters ? "Try removing filters or expanding the time window." : "";

  return (
    <div className={"flex flex-col justify-center items-center mt-[10%]"}>
      <Typography variant="h3">{titleProps || title}</Typography>
      <Typography variant="subtitle1">{descriptionProps || description}</Typography>
    </div>
  );
}
