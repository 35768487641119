import React from "react";
import { IS_SAFARI } from "shared/checkBrowser";
import { HStack, Stack } from "libs/layouts";
import { css } from "ui/css";
import { Box, CssPropType } from "ui/atoms/Box";
import { space } from "ui/vars";

const safariStyles = IS_SAFARI
  ? {
      flexShrink: 0,
    }
  : {};

const contentStyles = css({
  height: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
});

export function TablePageTemplate({
  topbar = null,
  filters = null,
  children,
  sx,
}: {
  topbar?: React.ReactNode;
  filters?: React.ReactNode;
  children?: React.ReactNode;
  sx?: CssPropType;
}) {
  return (
    <Stack
      as={Box}
      sx={{
        paddingX: space[2],
        ...sx,
      }}
      space={2}
      style={{
        height: "100%",
        flex: "auto",
      }}
    >
      {(topbar || filters) && (
        <div className={"flex flex-col p-s bg-background-base3"}>
          {topbar}
          {filters}
        </div>
      )}
      <Box
        sx={{
          paddingX: space[3],
          paddingTop: filters ? space[3] : 0,
          marginX: space[-2],
        }}
        className={contentStyles().className}
      >
        {children}
      </Box>
    </Stack>
  );
}

export function TableTemplate({
  className,
  sx,
  tableFilters,
  caption,
  children,
  "data-testid": testId,
}: {
  tableFilters?: React.ReactNode;
  caption?: React.ReactNode;
  children: React.ReactNode;
  ["data-testid"]?: string;
  className?: string;
  sx?: CssPropType;
}) {
  return (
    <Stack space={2} style={{ height: "100%", ...sx }} className={className} data-testid={testId}>
      {tableFilters && (
        <Stack space={3} style={safariStyles}>
          {tableFilters}
        </Stack>
      )}
      <Box
        as={Stack}
        sx={{
          marginX: "calc(-1 * var(--spacing-m))",
        }}
        space={0}
        style={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {children}
        {caption && (
          <HStack
            data-fs="unmask"
            as={Box}
            sx={{
              paddingLeft: "var(--spacing-xs)",
              paddingY: "var(--spacing-xs)",
              border: `1px solid var(--palette-divider)`,
              borderTop: `none`,
            }}
            style={
              IS_SAFARI && {
                flexShrink: 0,
              }
            }
          >
            {caption}
          </HStack>
        )}
      </Box>
    </Stack>
  );
}
